import React from "react"
import headerStyles from "./header.module.css"
import {Link} from 'gatsby';
import Nav from "./nav"

export default function Header({page, open, setOpen}) {
  
  return (
    <div className={headerStyles.header}>
      <div className={headerStyles.headerBox}>
      <Link to='/' className='noHover'><h3>Julia Edwards</h3></Link> <p>Poet, Writer & Editor</p>
     </div>
     
      <Nav page={page} open={open} setOpen={setOpen}/>
    </div>
  )
}
